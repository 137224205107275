<template>
  <el-main>
    <div class="searchdiv">
      <div>
        <el-button type="primary" @click="addnode" icon="el-icon-plus" v-if="userinfo.role_id==1">添加菜单</el-button>
        <span class="title">节点管理  <span class="subtitle">-- 控制前端菜单和后台的权限控制  主要是开发在用这个，正式环境不应该配置出这个菜单来</span> </span>
      </div>
    </div>
    <div class="pagecontent">
      <el-table
          ref="multipleTable"
          :data="nodelist"
          tooltip-effect="dark"
          style="width: 100%"
          row-key="id"
          border
          default-expand-all
          :tree-props="{children: 'children'}">
        <el-table-column label="Node Name" width="240">
          <template slot-scope="scope">
            {{ scope.row.title }}
          </template>
        </el-table-column>
        <el-table-column label="apiuri" width="300" v-if="userinfo.role_id==1">
          <template slot-scope="scope">
            <el-popover v-if="scope.row.apiuri!=''" placement="bottom"
                        width="300"
                        trigger="click">
              <div>
                {{ scope.row.apiuri }}
              </div>
              <div slot="reference" style="white-space:nowrap;max-width:250px;text-overflow:ellipsis">
                {{ scope.row.apiuri }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column v-if="userinfo.role_id==1"
                         label="router"
                         prop="router">
        </el-table-column>
        <el-table-column label="icon" v-if="userinfo.role_id==1">
          <template slot-scope="scope">
            <i v-if="scope.row.icon" :class="scope.row.icon"></i>
          </template>
        </el-table-column>
        <el-table-column label="Is Menu">
          <template slot-scope="scope">
            <el-link :type="scope.row.ismenu?'success':'danger'" @click="setmenu(scope.row)">
              {{ scope.row.ismenu ? $t('global.yes') : $t('global.no') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('global.state')">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'" @click="setenable(scope.row)">
              {{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('global.action')" width="180" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" icon="el-icon-plus" @click="addnode(scope.row,'nodeform')">Append</el-link>
            <el-link class="optlink" @click="editnode(scope.row)">{{ $t('global.edit') }}</el-link>
            <el-link class="optlink" @click="delnode(scope.row)">{{ $t('global.delete') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="nodeformdata.id?'modify node':'add node'" :visible.sync="isshowdialog"
               :close-on-click-modal="false">
      <el-form :model="nodeformdata" :rules="rules" ref="nodeform" :show-message="false">

        <el-form-item label="pid" label-width="120px" prop="pid" required>
          <el-select v-model="nodeformdata.pid" placeholder="parent" @change="this.$forceUpdate()">
            <el-option :key="0" :label="'顶级节点'" :value="0">顶级节点</el-option>
            <el-option
                v-for="item in parentlist"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              <span v-html="item.title"></span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="标题" label-width="120px" prop="title" required>
          <el-input v-model="nodeformdata.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="英文标题" label-width="120px" prop="title_en" required>
          <el-input v-model="nodeformdata.title_en" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="apiuri" label-width="120px" prop="apiuri">
          <el-input v-model="nodeformdata.apiuri" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="router" label-width="120px" prop="router">
          <el-input v-model="nodeformdata.router" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="icon" label-width="120px" prop="icon">
          <el-input v-model="nodeformdata.icon" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="status" label-width="120px">
          <el-switch v-model="nodeformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')"
                     :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item label="ismenu" label-width="120px">
          <el-switch v-model="nodeformdata.ismenu" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')"
                     :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('global.remarks')" label-width="120px" prop="remarks">
          <el-input type="textarea" v-model="nodeformdata.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('nodeform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
// @ is an alias to /src
import {ORDER_CODE} from "@/libs/const";

export default {
  name: 'node',
  components: {},
  data() {
    return {
      nodelist: [],
      parentlist: [],
      totalRows: 0,
      defaultnodeinfo: {
        id: 0,
        title: '',
        title_en: '',
        status: 1,
        remarks: '',
        apiuri: '',
        router: '',
        icon: '',
        ismenu: 0,
        pid: 0
      },
      nodeformdata: {},
      rules: {},
      isshowdialog: false,
      isbusy: false,
    }
  },
  methods: {
    async getnodelist() {
      this.isbusy = true;
      let res = await this.httpget('/manager/node/index');
      if (res.code === 0) {
        this.nodelist = res.data;
        //JSON.parse(JSON.stringify())主要用来实现深拷贝 不想引入lodash之类的插件 没有太复杂的数据深拷贝
        this.parentlist = this.tree_to_array(JSON.parse(JSON.stringify(res.data)), 0);
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }
    },
    editnode(item) {
      this.nodeformdata = null;
      this.nodeformdata = this.cloneData(item);
      this.isshowdialog = true;
    },
    addnode(item, formName) {
      this.nodeformdata = this.cloneData(this.defaultnodeinfo);
      console.log(item, item.id);
      if (item) {
        this.nodeformdata.pid = item.id;
        this.$forceUpdate();
      }
      this.isshowdialog = true;
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/manager/node/save', this.nodeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getnodelist();
        this.nodeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/manager/node/setenable', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getnodelist();
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async setmenu(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/manager/node/setmenu', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getnodelist();
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async delnode(item) {
      this.$confirm('Are you sure to delete this item?').then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/manager/node/delete', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getnodelist();
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    tree_to_array(treelist, level) {
      level = level || 0;
      let newtreelist = [];
      treelist.forEach((tree) => {
        var str = '';
        for (var i = 0; i < level; i++) {
          str += '&nbsp;&nbsp;';
        }
        str += level > 0 ? '└&nbsp;' : '';
        tree.label = tree.title;
        tree.title = str + tree.title;
        if (tree.children) {
          var children = this.cloneData(tree.children);
          tree.children = undefined;
          newtreelist.push(tree);
          var tmptreelist = this.tree_to_array(children, level + 1);
          tmptreelist.forEach((tmptree) => {
            newtreelist.push(tmptree);
          })
        } else {
          newtreelist.push(tree);
        }
      })
      return newtreelist;
    }
  },
  mounted() {
    this.getnodelist();
  },
  computed: {
    userinfo: {
      get: function () {
        return this.$store.getters.getUserInfo;
      }
    },
    currLang: {
      get: function () {
        return this.$store.getters.getCurrLang || 'cn';
      },
      set(val) {
        this.$store.commit('setCurrLang', val);
      }
    },
  },
}
</script>
